.searchMenu {
  min-height: 500px;
  padding: 12px;
  text-align: left;
}

.searchBar {
  height: 30px;
  border: solid 2px #0e60e4;
  border-radius: 6px;
  background: #fefefe;
  display: flex;
  padding: 0 10px;

  .uicon {
    filter: brightness(0.6);
    margin-right: 12px;
  }

  input {
    width: 400px;
    color: #222;
  }
}

.opts div {
  border: solid 0 #1f4aff;
  cursor: pointer;

  &[value="true"] {
    border-bottom-width: 2px;
  }
}

.leftSide {
  width: 100%;
}

.leftSide[data-width="true"] {
  width: 32%;
}

.rightSide {
  margin: 8px;
  height: 400px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hline {
    width: 90%;
    height: 1px;
    border-radius: 10px;
    background: #dfdfdf;
  }
}

.topApp {
  width: 19%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
}

.qksrch {
  color: #111;
}

.qksrch svg {
  color: #333;
}

.calnpane {
  position: absolute;
  bottom: 12px;
  right: 12px;
  // width: 280px;
  // min-height: 300px;
  background: rgba(255, 255, 255, 0.88);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition: all ease-in-out 200ms;
  z-index: 9999;
  border-radius: 6px;

  &[data-hide="true"] {
    bottom: 10px;
    transform: translateY(120%);
  }
}
